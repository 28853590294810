@import "src/assets/scss/colors";
@import "src/assets/scss/fonts";
@import "src/assets/scss/breakpoints";

.container {
  width: 100%;
  padding: 32px;
  @include responsive("mdDown") {
    padding: 16px;
  }
  .sectionWrapper {
    display: grid;
    grid-template-columns: 40% 60%;
    min-height: calc(100vh - 64px);
    box-shadow: -5px 0px 10px 0px rgba(0, 0, 0, 0.05);
    @include responsive("mdDown") {
      grid-template-columns: 1fr;
    }
    .leftSection {
      background-color: $green-2;
      padding: 64px;
      border-radius: 8px 0px 0px 8px;
      font-family: "SF-pro";
      color: $white-0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      @include responsive("mdDown") {
        border-radius: 8px 8px 0px 0px;
        padding: 48px;
      }
      @include responsive("smDown") {
        padding: 24px;
      }
      @include responsive("xsDown") {
        padding: 16px;
      }
      .topSection {
        .logo {
          margin-bottom: 52px;
          @include responsive("mdDown") {
            margin-bottom: 0px;
          }
        }
        .info {
          max-width: 286px;
          font-size: 24px;
          font-weight: 500;
          line-height: 140%; /* 33.6px */
          letter-spacing: 0.96px;
          margin-top: 52px;
          @include responsive("smDown") {
            margin-top: 32px;
          }
        }
        .returnText {
          display: flex;
          gap: 12px;
          align-items: center;
          color: $white-0;
          font-size: 16px;
          font-weight: 400;
          line-height: 23px; /* 150% */
          margin-top: 44px;
          @include responsive("mdDown") {
            margin-top: 32px;
            margin-bottom: 32px;
          }
          letter-spacing: 0.64px;
          svg {
            g {
              path {
                fill: $white-0;
              }
            }
          }
        }
      }
      .bottomSection {
        line-height: 24px; /* 150% */
        letter-spacing: 0.64px;
        display: flex;
        align-items: center;

        gap: 14px;
        @include responsive("xsDown") {
          gap: 4px;
          flex-direction: column;
          align-items: flex-end;
        }
        .poweredByStripe {
          display: flex;
          gap: 5px;
          align-items: center;
        }
        .divider {
          height: 24px;
          width: 1px;
          border-left: 1px solid rgba(255, 255, 255, 0.4);
          @include responsive("xsDown") {
            display: none;
          }
        }
        .link {
          color: $white-0;
          text-decoration: none !important;
          line-height: 10px;
        }
      }
    }
    .rightSection {
      background-color: $white-0;
      border-radius: 0px 8px 8px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      @include responsive("mdDown") {
        border-radius: 0px 0px 8px 8px;
        padding: 48px;
      }
      @include responsive("smDown") {
        padding: 24px;
      }
      @include responsive("xsDown") {
        padding: 16px;
      }
    }
  }
}
