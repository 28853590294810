@import "src/assets/scss/colors.scss";
@import "src/assets/scss/fonts.scss";
@import "src/assets/scss/breakpoints.scss";

.breadCrumbsWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 16px;
  .breadCrumb {
    color: $blue-8;
    font-family: "SF-pro";
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.64px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;
    &.active {
      cursor: default;
      color: $blue-9;
    }
  }
}
