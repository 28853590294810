@import "src/assets/scss/colors";
@import "src/assets/scss/fonts";
@import "src/assets/scss/breakpoints";

.formWrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .divider {
    text-align: center;
    color: $gray-34;
    font-family: "SF-pro";
    font-size: 18px;
    font-weight: 400;
    line-height: 145%;
    padding: 34px 0px 18px 0px;
    .wordWithLine {
      display: inline-block;
      position: relative;
      padding: 0px 8px;
      &::before,
      &::after {
        max-width: 140px;
        content: "";
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $gray-33;
        top: 50%;
      }
      &::before {
        right: 100%;
      }
      &::after {
        left: 100%;
      }
    }
  }
  .paymentForm {
    max-width: 445px;
    width: 100%;
  }
  .btn {
    margin-top: 32px;
    max-width: 445px;
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    box-shadow:
      0px 3px 6px 0px rgba(0, 0, 0, 0.02),
      0px 1px 1px 0px rgba(0, 0, 0, 0.03);
  }
  .cancelBtn {
    margin: 24px 0px;
    max-width: 445px;
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 600;
    box-shadow:
      0px 3px 6px 0px rgba(0, 0, 0, 0.02),
      0px 1px 1px 0px rgba(0, 0, 0, 0.03);
    @include responsive("smDown") {
      margin: 16px 0px;
    }
  }
}
