@import "src/assets/scss/colors";
@import "src/assets/scss/fonts";
@import "src/assets/scss/breakpoints";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  @include responsive("smDown") {
    -webkit-tap-highlight-color: transparent;
  }
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", "Inter",
    "Marko One", monospace;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: none;
  -webkit-box-shadow: 0 0 0px 1000px transparent inset;
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #000;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 3px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 3px;
  background: $gray-15;
}

/* Remove thumb for Webkit browsers (Chrome, Safari) */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0;
  height: 0;
  background: transparent;
}

/* Remove thumb for Firefox */
input[type="range"]::-moz-range-thumb {
  appearance: none;
  width: 0;
  height: 0;
  background: transparent;
}

$sideBarWidth: 270px;

@font-face {
  font-family: "SF-pro";
  src: url(../public/assets/SFPro-Regular.ttf);
}
