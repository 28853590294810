@import "src/assets/scss/colors";
@import "src/assets/scss/fonts";
@import "src/assets/scss/breakpoints";

.paymentFormWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .body {
    .breadCrumbWrapper {
      margin: 64px 0;
      @include responsive("mdDown") {
        margin: 0px 0px 32px 0px;
      }
      @include responsive("smDown") {
        margin: 0px 0px 24px 0px;
      }
    }
    max-width: 445px;
    width: 100%;
    .title {
      color: $black-0;
      font-family: "SF-pro";
      font-size: 24px;
      font-weight: 700;
      line-height: normal;
      letter-spacing: 0.48px;
      margin-bottom: 32px;
      @include responsive("smDown") {
        margin-bottom: 24px;
      }
    }
    .btnWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 16px;
      .btn {
        width: 445px;
        padding: 12px;
        font-family: "SF-pro";
        font-size: 16px;
        font-weight: 600;
        line-height: 145%; /* 23.2px */
        border-radius: 5px;
      }
    }
  }
}
