/**
* Color
**/

// White
$white-0: #fff;
$white-1: #fcfcfc;
$white-2: #ebf6f3;
$white-3: #fafbfb;
$white-4: #f2f4f5;
$white-5: #fff7f3;
$white-6: #eff3ff;
$white-7: #f6f7f7;
$white-8: #f0f3f5;
$white-9: #e3e3e3;
$white-10: #f3f3f3;
// Black
$black-0: #000;
$black-1: #303132;
$black-2: #161616;
$black-3: #2f2f2f;
$black-4: #161717;
$black-5: #151617;
$black-6: #0d1010;
$black-7: #363739;
$black-8: #1f2122;
$black-9: #0c0d0d;

// Gray
$gray-0: #dcdcdc;
$gray-1: #f2f5f7;
$gray-2: #bababa;
$gray-3: #757575;
$gray-4: #bdbdbd;
$gray-5: #434343;
$gray-6: #d7dde1;
$gray-7: #999;
$gray-8: #8c8d8c;
$gray-9: #e3e7e9;
$gray-10: #525252;
$gray-11: #9faaa7;
$gray-12: #e6edeb33;
$gray-13: #f3f4f3;
$gray-14: #a5abad;
$gray-15: #d9d9d9;
$gray-16: #f2f5f6;
$gray-17: #838383;
$gray-18: #88918e;
$gray-19: #d1d1d1;
$gray-20: #f6f6f6;
$gray-21: #525354;
$gray-22: #989898;
$gray-23: #767676;
$gray-24: #fcfcfc;
$gray-25: #a6acaf;
$gray-26: #525557;
$gray-27: #d5dadd;
$gray-28: #828689;
$gray-29: #f7f9fa;
$gray-30: #e4e4e4;
$gray-31: #dee1e3;
$gray-32: #dfe1e3;
$gray-33: #e6e6e6;
$gray-34: #979797;

// Blue
$blue-0: #0072f5;
$blue-1: #3665dd;
$blue-2: #f7fafc;
$blue-3: #ebf2f6;
$blue-4: #2e35500d;
$blue-5: #0f2552;
$blue-6: #d5e1ff;
$blue-7: #5383ff;
$blue-8: #484d61;
$blue-9: #697385;
//Yellow
$yellow-0: #f5a524;
$yellow-1: #fff9e2;
$yellow-2: #fed64a;
//Green
$green-0: #062e23;
$green-1: #195442;
$green-2: #014935;
$green-3: #bff2e2;
$green-4: #052b20;
$green-5: #e8f4f1;
$green-6: #f8fcfb;
$green-7: #f5fff9;
$green-8: #23a255;
$green-9: #2b7c63;
$green-10: #def1eb;
$green-11: #54bb9c;
$green-12: #36876e;
$green-13: #ebf6f3;
$green-14: #c1e2d8;
$green-15: #d3ffe5;
$green-16: #1ba251;
$green-17: #35d575;
$green-18: #90d3be;

//Orange
$orange-0: #f05a28;
$orange-1: #fbb88b;
$orange-2: #ea7838;
$orange-3: #f56839;
$orange-4: #fff7f4;
$orange-5: #f7a78c;
$orange-6: #ffdcd1;
//Red
$red-0: #c40000;
$red-1: #ea3838;
$red-2: #ffd6d6;
$red-3: #ac3209;
